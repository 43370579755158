<template>
  <div>
    <b-card>
        <Table :url="url" :fields="fields">

          <template #cell(total_amount)="data">
            {{ amountFormat(data.item.total_amount, data.item.invoice.currency.name) }}
          </template>

          <template #cell(date_of_purchase)="data">
            {{ data.item.created_at.substring(10,0) }}
          </template>

          <template #cell(due_date)="data">
            {{ data.item.invoice.due_date.substring(10,0) }}
          </template>

          <template #cell(roi_percent)="data">
            {{ calculateRoiPercent(data.item) }}
          </template>

          <template #cell(roi)="data">
            {{ data.item.total_net_return }}
          </template>

          <template #cell(progress)="data">
            {{data.item.invoice.fraction}}
            <b-progress
              variant="success"
              :value="data.item.total_percentage"
              max="100"
              width="100px"
              height="14px"
            />
            <span> {{ data.item.total_percentage }}% </span>
          </template>
        </Table>
    </b-card>
  </div>
</template>

<script>
import Table from "@/components/common/Table2.vue";
import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';


export default {
  name: "MyInvoices",

  components: {
    Table,
  },
  data() {
    return {
      fields: [
        {
          key: "invoice_id",
          label: "Id",
        },
        {
          key: "total_amount",
          label: "amount",
        },
        // {
        //   key: "date_of_purchase",
        //   label: "date_of_purchase",
        // },
        // {
        //   key: "due_date",
        //   label: "due_date",
        // },
        // {
        //   key: "roi_percent",
        //   label: "roi_percent",
        // },
        // {
        //   key: 'roi',
        //   label: "roi",
        // },
        // {
        //   key: "rating",
        //   label: "rating",
        // },
        // {
        //   key: "seller_description",
        //   label: "seller_description",
        // },
        // {
        //   key: "debtor_description",
        //   label: "debtor_description",
        // },
        // {
        //   key: "recourse_type",
        //   label: "recourse_type",
        // },
        // {
        //   key: "seller_location",
        //   label: "seller_location",
        // },
        // {
        //   key: "debtor_location",
        //   label: "debtor_location",
        // },
        {
          key: "progress",
          label: "funding_st  atus",
        },
        // {
        //   key: "title",
        //   text: "title",
        //   label: "Document",
        //   sortable: true,
        //   value: "title",
        // },
        // { key: "ext", label: "Ext", sortable: true, value: "ext", text: "ext" },
        // {
        //   key: "company",
        //   label: "Company",
        //   sortable: true,
        //   value: "company",
        //   text: "company",
        // },
      ],
      title: "my_invoices",
      url: "/invoice/my_invoices",
    };
  },
  methods: {

    calculateRoiPercent(fraction) {
      console.log(fraction)
      let roi = fraction.total_net_return * 100 / fraction.total_amount.tofixed(2)
      return roi
    },

    amountFormat(amount, currency) {
      return CurrencyAmountFormat(amount, currency)
    },
  },
};
</script>


<style scoped>

</style>